export const environment = {
  production: true,
  appVersion: 'v726demo1',
  USERDATA_KEY: 'authf649fc9a5f55',
  isMockEnabled: true,
  apiUrl: 'https://naiback.omarzaarour.com',
  graphqlEndpoint: 'https://naiback.omarzaarour.com/graphql',
  uploadEndpoint: 'https://naiback.omarzaarour.com/upload',
  publicEndPoint: 'https://naiback.omarzaarour.com/uploads/'
};

